import Button from '@components/Button';
import AdminConfig from '@config/admin';
import '@css/Admin/index.scss';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { dateLocale, slugfy, translator } from '@utils/helpers';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  // Redirect,
  useParams, useRouteMatch
} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import Header from './partials/Header';
import Modal from '@components/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import calendar from '@assets/images/icons/calendar.png';
import clock from '@assets/images/icons/clock.png';
import person from '@assets/images/icons/person.png';
import Checkbox from '@components/Checkbox';

class Content extends React.Component {
  render() {
    const props = this.props;
    const idiom = (props.voucher.customerData.language || 'pt').toUpperCase();
    const payment_status = {
      accomplished: 'Pago',
      pending: 'Pendente',
      partial: 'Parcial'
    };

    const language = {
      pt: 'Português',
      en: 'Inglês',
      es: 'Espanhol'
    };

    const formatHour = time => {
      if (time) {
        const hourArrar = time.split(":")
        const hour = new Date().setHours(hourArrar[0], hourArrar[1]);
        return format(hour, "HH:mm")
      }
      return null
    }
    const calcAdultsValue = (v) => {
      const adultValue = Number(v?.adults_total_amount)
      const adultQuantity = Number(v?.adults_total)
      const value = adultQuantity > 1 ? adultValue / adultQuantity : adultValue
      return value
    }
    const calcKidsValue = (v) => {
      const adultValue = Number(v?.kids_total_amount)
      const adultQuantity = Number(v?.kids_total)
      const value = adultQuantity > 1 ? adultValue / adultQuantity : adultValue
      return value
    }
    const cleanText = (text) => {
      let final = text.replace('style', 'space')
      return final
    }
    const getTimes = (v) => {
      if (v.travel.tour_period === 'fullday') {
        return 'Integral'
      }
      return v.times
    }

    return <>
      <div id='desktop' className="p-10 bg-white border-1 text-blue hidden sm:hidden md:hidden lg:block xl:block" style={{ borderRadius: '10px 10px 0px 0px', maxWidth: '1350px' }}>
        <>
          <div className="flex justify-space-around">
            <div className="flex flex-col text-center mr-3">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center" alt="" style={{ width: '120px' }} />
              </div>
              {/* <span className="mt-2">{process.env.REACT_APP_NAME}</span> */}
            </div>
            <div className="flex flex-row justify-between space-y-1" style={{ width: '100%' }}>
              <div className="text-left" style={{ fontSize: '0.7rem' }}>
                <div className="flex flex-col text-lg">
                  <p>Voucher de orçamento</p>
                </div>
                <p>{props.voucher.company.name}</p>
                <p>{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
              </div>
              <div className="text-right mt-5 pt-5" style={{ fontSize: '0.7rem' }}>
                <p>{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address} - {props.voucher.company.district} - {props.voucher.company.state} - {props.voucher.company.country}</p>
                <p>{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="my-5 border-t border-b border-solid border-lightgray"></div>

          <div className="flex flex-row text-left">
            <div className="flex flex-col text-left">
              <div className="flex my-5 text-xl">
                {translator(idiom, 'voucher', 'customers_data')}
              </div>
              <div className="flex flex-col text-left">
                <p><span className="font-bold">{translator(idiom, 'voucher', 'customer_name')}:</span> {props.voucher.customer.name}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'phone')}:</span> {props.voucher.customer.phone}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'email')}:</span> {props.voucher.customer.email}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'document')}:</span> {props.voucher.customer.document}</p>
              </div>
            </div>
          </div>

          <div className="flex justify-center mb-5 mt-12 text-xl ">
            {translator(idiom, 'voucher', 'about_budget')}
          </div>

          {props.voucher.budgets.map((v) => (
            <div className="flex flex-row">
              <div className="flex flex-col">
                <p className="my-3 text-lg text-left">
                  {v?.travel?.map((t) => {
                    if (t.region_name) {
                      return t.region_name
                    } else {
                      return t.name
                    }
                  })} {v?.transfer_type ? `- Transfer ${v?.transfer_type}` : ''}
                </p>
                <div className="flex flex-row justify-between">

                  <div className="grid grid-cols-8 lg:grid-cols-4 justify-between">
                    <div className="col-span-3 lg:col-span-2">
                      <div className="flex text-left">
                        <div className="flex flex-row flex-wrap mx-2">
                          <span className="mx-2 flex"><span className="mr-2"><img src={calendar} /></span>Aguardando Agendamento</span>
                          <span className="mx-2 flex"><span className="mr-2"><img src={clock} /></span> {v?.times !== null ? getTimes(v) : formatHour(v?.flight_time) || '-'}</span>
                          <span className="mx-2 flex"><span className="mr-2"><img src={person} /></span> {parseInt(v?.adults_total)} adultos, {v?.kids_total ? parseInt(v?.kids_total) : 0} crianças</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-5 lg:col-span-2">
                      <div className="flex text-left">
                        <div className="flex flex-row">
                          <div className="flex flex-col mx-3"><span><span className="font-bold">{translator(idiom, 'voucher', 'value_by_adult')}:</span> <NumberFormat
                            value={v.travel_type === 'tour' ? v?.travel[0]?.price : v?.price / parseInt(v?.adults_total)}
                            displayType={'text'}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalPrecision={2}
                            thousandSeparator={"."}
                            isNumericString
                            prefix={`${process.env.REACT_APP_CURRENCY} `}
                            renderText={(value) => value}
                          /></span>
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'value_by_kid')}:</span> <NumberFormat
                              value={v?.travel[0]?.kids_price}
                              displayType={'text'}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalPrecision={2}
                              thousandSeparator={"."}
                              isNumericString
                              prefix={`${process.env.REACT_APP_CURRENCY} `}
                              renderText={(value) => value}
                            /></span>
                          </div>

                          <div className="flex flex-col mx-2">
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'subtotal')}:</span> <NumberFormat
                              value={v.travel_type === 'tour' ? v?.price : v?.price}
                              displayType={'text'}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalPrecision={2}
                              thousandSeparator={"."}
                              isNumericString
                              prefix={`${process.env.REACT_APP_CURRENCY} `}
                              renderText={(value) => value}
                            /></span>
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'discount')}:</span> <NumberFormat
                              value={v?.price * v?.discount_total_sale / 100}
                              displayType={'text'}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalPrecision={2}
                              thousandSeparator={"."}
                              isNumericString
                              prefix={`${process.env.REACT_APP_CURRENCY} `}
                              renderText={(value) => value}
                            />
                            </span>
                          </div>
                          <div className="flex flex-col mx-2">
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'total')}:</span> <NumberFormat
                              value={v?.travel_type === 'tour' ? v?.price - (v?.price * v?.discount_total_sale / 100) : v?.price - (v?.price * v?.discount_total_sale / 100)}
                              displayType={'text'}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalPrecision={2}
                              thousandSeparator={"."}
                              isNumericString
                              prefix={`${process.env.REACT_APP_CURRENCY} `}
                              renderText={(value) => value}
                            /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 my-2"></div>
                </div>
              </div>
            </div>
          ))}

          <div className="px-4 my-2 mb-10 font-poppins"></div>

          <div className="flex justify-center my-5 text-xl ">
            Informações do vendedor
          </div>
          <div className="flex flex-col space-y-1 text-left">
            <p>Nome do vendedor: {props.voucher?.seller[0]?.name}</p>
            <p>E-mail: {props.voucher?.seller[0]?.email}</p>
          </div>

          <div className="flex mt-16">
            <div className="flex flex-col flex-1 space-y-1 text-center">
              <p>{translator(idiom, 'voucher', 'customer_name')}: {props.voucher.customerData.name}</p>
              <p>ID: {props.voucher.id}</p>
              <p>Data do orçamento: {dateLocale(props.voucher.created_at)}</p>
              <p>Esse orçamento pode sofrer alterações</p>
            </div>
          </div>

        </>
      </div >

      <div className="p-8 bg-white border-1 text-blue block xs:block sm:block md:block lg:hidden xl:hidden" style={{ borderRadius: '10px 10px 0px 0px', fontSize: '14px', display: 'none' }}>
        <>
          <div className="flex justify-space-around">
            <div className="flex flex-col text-center mr-3">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center" alt="" style={{ width: '80px' }} />
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <div className="text-left" style={{ fontSize: '0.7rem' }}>
                <div className="flex flex-col text-lg">
                  <p>Voucher de orçamento</p>
                </div>
                <p className="text-left">{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address}</p>
                <p className="text-left">{props.voucher.company.district}</p>
                <p className="text-left">{props.voucher.company.state} - {props.voucher.company.country}</p>
                <p className="text-left">{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
                <p className="text-left">{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="my-4 border-t border-b border-solid border-lightgray"></div>
          <div className="flex my-5 text-lg ">
            {translator(idiom, 'voucher', 'customers_data')}
          </div>
          <div className="flex flex-col space-y-1 text-left">
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'customer_name')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.customer.name}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'phone')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.customer.telephone}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'email')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.customer.email}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'document')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.customer.document}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  Idioma:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {language[props.voucher.customer.language]}
                </div>
              </div>
            </div>
            {props.voucher?.reservationData?.combo_id && <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'address')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                  {props.voucher.reservationData.address_hotel}
                </div>
              </div>
            </div>}
            {props.voucher?.reservationData?.translate_id && <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'address')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.reservationData.address_hotel}
                </div>
              </div>
            </div>}
          </div>

          <div className="flex mb-5 mt-12 text-lg">
            {translator(idiom, 'voucher', 'about_budget')}
          </div>
          <div className="flex flex-col">
            {props.voucher.budgets.map((v) => (
              <div className="flex flex-col" style={{ width: '100%' }}>
                <>
                  <p className="my-3 text-left font-bold">
                    {v?.travel?.map((t) => {
                      if (t.region_name) {
                        return t.region_name
                      } else {
                        return t.name
                      }
                    })} {v?.transfer_type ? `- Transfer ${v?.transfer_type}` : ''}
                  </p>
                </>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                    {translator(idiom, 'voucher', 'date')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                    {translator(idiom, 'voucher', 'waiting_date')}
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                    Criado em
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                    <Moment format="DD/MM/YYYY" date={v?.created_at} />
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start justify-center flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'value_by_adult')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                    <NumberFormat
                      value={v.travel_type === 'tour' ? v?.travel[0]?.price : v?.price / parseInt(v?.adults_total)}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <span className="flex items-center">{value}</span>}
                    />
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'adults')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                    {parseInt(v?.adults_total)}
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start justify-center flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'value_by_kid')}
                  </div>
                  <span class="flex space-x-1">
                    <span>
                      <NumberFormat
                        value={v?.travel[0]?.kids_price}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </span>
                  </span>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'kids')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                    {v?.kids_total}
                  </div>
                </div>
                <div className="px-4 my-1"></div>

                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'subtotal')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                    <NumberFormat
                      value={v.travel_type === 'tour' ? v?.price : v?.price}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <span className="flex items-center">{value}</span>}
                    />
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'discount')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                    <span class="flex space-x-1">
                      <NumberFormat
                        value={v?.price * v?.discount_total_sale / 100}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </span>
                  </div>
                </div>
                <div className="px-4 my-1"></div>

                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'total')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                    <span>
                      <NumberFormat
                        value={v?.travel_type === 'tour' ? v?.price - (v?.price * v?.discount_total_sale / 100) : v?.price - (v?.price * v?.discount_total_sale / 100)}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </span>
                  </div>
                </div>

                <div className="my-4"></div>
              </div>
            ))}
          </div>
          <div className="flex flex-col">
            <div className="flex justify-center my-5 text-xl ">
              Informações do vendedor
            </div>
            <div className="flex flex-col">
              <p>Nome do vendedor: {props.voucher?.seller[0]?.name}</p>
              <p>E-mail: {props.voucher?.seller[0]?.email}</p>
            </div>
          </div>
          <div className="my-4"></div>
          <div className="flex flex-col">
            <p>{translator(idiom, 'voucher', 'customer_name')}: {props.voucher.customerData.name}</p>
            <p>ID: {props.voucher.id}</p>
            <p>{translator(idiom, 'voucher', 'purchase_date')}: {dateLocale(props.voucher.created_at)}</p>
            <p>{translator(idiom, 'voucher', 'observation')}</p>
          </div>
        </>
      </div>
    </>
  }
}

const VoucherLinkExit = props => {
  let { path } = useRouteMatch();
  const history = useHistory();
  const componentRef = useRef()
  const { sale_id, token } = useParams();
  const [voucher, setVoucher] = useState({});
  const [loading, setLoading] = useState(true);
  const [sendingVoucher, setSendingVoucher] = useState(false);
  let url = `/customer/get_presale_voucher/${sale_id}?access_token=${token}`;
  const [success, setSuccess] = useState(false);
  const sendVoucherUrl = `presales/${sale_id}/send_voucher`;

  const getVoucher = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setVoucher(response.data);
        setLoading(false);
      }
    }).catch(function (response) {
      history.push('/');
    });
  }

  const sendVoucher = () => {
    setSendingVoucher(true);
    apiClient.httpInstance.post(sendVoucherUrl).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setSendingVoucher(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    });
  }

  useEffect(() => {
    getVoucher(url);
  }, [url]);

  let theme = AdminConfig.themes[slugfy(props?.user?.data?.permission)] || AdminConfig.themes.logistica;

  return <Switch>
    <React.Fragment>
      <div className="flex flex-col admin">
        <Header theme={theme} />
        <div className="flex flex-row bg-lightgray">
          <Route path={`${path}`}>
            <div className="w-full flex justify-center mx-0 mb-20 pt-14 md:mx-28">
              <>
                {!loading && <div className="px-7">
                  <div className="flex items-center justify-between mt-2 text-2xl text-blue">
                    <span>Documento</span>
                    <div className="space-x-3 w-100">
                      <ReactToPrint
                        trigger={() => <Button
                          textcolor="#FFF"
                          padding="6px 15px"
                          width="200"
                        >
                          Visualizar PDF
                        </Button>
                        }
                        content={() => componentRef.current}
                        onBeforeGetContent={() => {
                          document.getElementById('desktop').classList.remove('hidden')
                          document.getElementById('desktop').classList.remove('sm:hidden')
                          document.getElementById('desktop').classList.remove('md:hidden')
                        }}
                        onAfterPrint={() => {
                          document.getElementById('desktop').classList.add('hidden')
                          document.getElementById('desktop').classList.add('sm:hidden')
                          document.getElementById('desktop').classList.add('md:hidden')
                        }}
                        onPrintError={() => {
                          document.getElementById('desktop').classList.add('hidden')
                          document.getElementById('desktop').classList.add('sm:hidden')
                          document.getElementById('desktop').classList.add('md:hidden')
                        }}
                        pageStyle="@page { size: 10in 13.5in; }"
                      />

                    </div>
                  </div>
                  <div className="my-4 d-flex">
                    {success && <Alert className="mt-5" severity="success" color="success">
                      Documento enviado com sucesso
                    </Alert>}
                  </div>
                  <Content
                    {...props}
                    voucher={voucher}
                    ref={componentRef}
                  />
                </div>}
              </>
            </div>
          </Route>
        </div>
      </div>
    </React.Fragment>
  </Switch>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(VoucherLinkExit);
