import { useState } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@components/Button';
import DiscountModal from '../partials/DiscountModal';

const TableBodyCart = (props) => {
  const [openDiscount, setOpenDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [justify, setJustify] = useState('');
  const [toApprove, setToApprove] = useState(false);
  const [finished, setFinished] = useState(props.data.discount_total_sale);

  const setSaleDiscount = (ev) => {
    props.data.cart[ev.target.name] = ev.target.value

    if (ev.target.name === 'discount_total_sale') {
      setDiscount(ev.target.value)
    } else {
      setJustify(ev.target.value)
    }
  }

  const discountTotal = () => {
    const total = props.data.cart.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    return total * discount / 100
  }
  
  const discountTotalSale = () => {
    const total = props.data.cart.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    return total * props.data.cart?.discount_total_sale / 100
  }

  const calcSubtotal = () => {
    const total = props.data.cart.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    return Number(total)
  }
  
  const calcTotal = () => {
    const total = props.data.cart.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    let calculated = calcSubtotal()
    if (props.data.cart?.discount_total_sale) {
      calculated = calculated - discountTotalSale()
    }
    return calculated
  }

  return <>
    <TableBody>
      {props.data.cart.map((cartValue) => (
        <TableRow>
          <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
            <div className="flex items-center">
              <div className="flex-none">
                <img src={cartValue.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${cartValue.banner}` : null} width="60" className="mr-3 rounded-full" alt="" />
              </div>
              <div className="text-lg font-medium">
                {cartValue.travel_name} {cartValue.transfer_type ? `(${cartValue.transfer_type})` : null}
              </div>
            </div>
          </TableCell>
          <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
            {cartValue.cupos}
          </TableCell>
          <TableCell className="leading-5 border-0 text-blue" component="th" scope="row">
            <NumberFormat
              value={Number(cartValue.total_amount).toFixed(2)}
              displayType={'text'}
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalPrecision={2}
              thousandSeparator={"."}
              isNumericString
              prefix={`${process.env.REACT_APP_CURRENCY} `}
              renderText={(value) => <span>{value}</span>}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <TableRow>
        <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
          Subtotal
        </TableCell>
        <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
          <NumberFormat
            value={calcSubtotal().toFixed(2)}
            displayType={'text'}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalPrecision={2}
            thousandSeparator={"."}
            isNumericString
            prefix={`${process.env.REACT_APP_CURRENCY} `}
            renderText={(value) => <span>{value}</span>}
          />
        </TableCell>
      </TableRow>
      {props.data.cart?.discount_total_sale && <>
        <TableRow>
          <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
            Descontos solicitados
          </TableCell>
          <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
          <NumberFormat
            value={discountTotalSale().toFixed(2)}
            displayType={'text'}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalPrecision={2}
            thousandSeparator={"."}
            isNumericString
            prefix={`${process.env.REACT_APP_CURRENCY} `}
            renderText={(value) => <span>{value}</span>}
          />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
            Justificativa
          </TableCell>
          <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
            {props.data.cart?.justify_discount}
          </TableCell>
        </TableRow>
      </>}
      {props.data.cart?.discount_total_sale && <>
        <TableRow>
          <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
            Descontos solicitados
          </TableCell>
          <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
          <NumberFormat
            value={discountTotalSale().toFixed(2)}
            displayType={'text'}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalPrecision={2}
            thousandSeparator={"."}
            isNumericString
            prefix={`${process.env.REACT_APP_CURRENCY} `}
            renderText={(value) => <span>{value}</span>}
          />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
            Justificativa
          </TableCell>
          <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
            {props.data.cart?.justify_discount}
          </TableCell>
        </TableRow>
      </>}
      <TableRow>
        <TableCell className="text-lg font-medium leading-5 border-0 text-blue">
          Total
        </TableCell>
        <TableCell align="right" colSpan={3} className="text-lg font-medium leading-5 border-0 text-blue">
          <NumberFormat
            value={calcTotal().toFixed(2)}
            displayType={'text'}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalPrecision={2}
            thousandSeparator={"."}
            isNumericString
            prefix={`${process.env.REACT_APP_CURRENCY} `}
            renderText={(value) => <span>{value}</span>}
          />
        </TableCell>
      </TableRow>
    </TableFooter>

    <DiscountModal
      setSaleDiscount={setSaleDiscount}
      setToApprove={setToApprove}
      discount={discount}
      justify={justify}
      open={openDiscount}
      close={() => setOpenDiscount(false)}
      {...props}
    />
  </>
}

export default TableBodyCart;
