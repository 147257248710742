const startYear = 2021;
const currentYear = new Date().getFullYear();

const yearIntervalOptions = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
    const year = startYear + index;
    return {
        value: year.toString(),
        title: year.toString()
    };
});

export { yearIntervalOptions };