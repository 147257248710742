import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Moment from 'react-moment';
import Button from '@components/Button';
import TextField from '@components/TextField';
import AdminConfig from '@config/admin';
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import { display, visibility } from '@material-ui/system';
import { apiClient } from '@services/api';
import { dateLocale, slugfy, translator } from '@utils/helpers';
import NumberFormat from 'react-number-format';
import Modal from '@components/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import calendar from '@assets/images/icons/calendar.png';
import clock from '@assets/images/icons/clock.png';
import person from '@assets/images/icons/person.png';
import '@css/Admin/index.scss';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams, useRouteMatch
} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import Header from './partials/Header';
import Checkbox from '@components/Checkbox';

const formatDate =(date)=>{
  if(date){
    const newDate = new Date(date)
    return `${newDate.toJSON().split('T')[0].split('-')[2]}/${newDate.toJSON().split('T')[0].split('-')[1]}/${newDate.toJSON().split('T')[0].split('-')[0]}`
  }
}
class Content extends React.Component {
  render() {
    const props = this.props;
    const idiom = (props.voucher.customerData.language || 'pt').toUpperCase();
    const payment_status = {
      accomplished: 'Pago',
      pending: 'Pendente',
      partial: 'Parcial'
    };

    const language = {
      pt: 'Português',
      en: 'Inglês',
      es: 'Espanhol'
    };

    let lastPaymentId = 0;

    const formatHour = time => {
      if (time) {
        const hourArrar = time.split(":")
        const hour = new Date().setHours(hourArrar[0], hourArrar[1]);
        return format(hour, "HH:mm")
      }
      return null
    }

    const calcAdultsValue = (v) => {
      const adultValue = Number(v?.adults_total_amount)
      const adultQuantity = Number(v?.adults_total)
      const value = adultQuantity > 1 ? adultValue / adultQuantity : adultValue
      return value
    }

    const calcKidsValue = (v) => {
      const adultValue = Number(v?.kids_total_amount)
      const adultQuantity = Number(v?.kids_total)
      const value = adultQuantity > 1 ? adultValue / adultQuantity : adultValue
      return value
    }

    const cleanText = (text) => {
      let final = text.replace('style', 'space')
      return final
    }

    const getTimes = (v) => {
      if (v.travel.tour_period === 'fullday') {
        return 'Integral'
      }
      return v.times
    }
    return <>
      <div id='desktop' className="p-10 bg-white border-1 text-blue hidden sm:hidden md:hidden lg:block xl:block" style={{ borderRadius: '10px 10px 0px 0px', maxWidth: '1350px' }}>
        <>
          <div className="flex justify-space-around">
            <div className="flex flex-col text-center mr-3">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center" alt="" style={{ width: '120px' }} />
              </div>
            </div>
            <div className="flex flex-row justify-between space-y-1" style={{ width: '100%' }}>
              <div className="text-left" style={{ fontSize: '0.7rem' }}>
                <div className="flex flex-col text-lg">
                  <p>Voucher de reserva</p>
                </div>
                <p>{props.voucher.company.name}</p>
                <p>{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
              </div>
              <div className="text-right mt-5 pt-5" style={{ fontSize: '0.7rem' }}>
                <p>{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address} - {props.voucher.company.district} - {props.voucher.company.state} - {props.voucher.company.country}</p>
                <p>{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="my-5 border-t border-b border-solid border-lightgray"></div>

          <div className="flex flex-row text-left justify-between">
            <div className="flex flex-col text-left mr-6">
              <div className="flex my-5 text-xl">
                {translator(idiom, 'voucher', 'customers_data')}
              </div>
              <div className="flex flex-col text-left">
                <p><span className="font-bold">{translator(idiom, 'voucher', 'customer_name')}:</span> {props.voucher.reservationData.customer_name}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'phone')}:</span> {props.voucher.reservationData.customer_telephone}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'email')}:</span> {props.voucher.customerData.email}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'document')}:</span> {props.voucher.customerData.document}</p>
                <p><span className="font-bold">Idioma:</span> {language[props.voucher.customerData.language]}</p>
                {props.voucher?.reservationData?.combo_id && <p><span className="font-bold">{translator(idiom, 'voucher', 'address')}:</span> {props.voucher.reservationData.address_hotel}</p>}
                {props.voucher?.reservationData?.translate_id && <p><span className="font-bold">{translator(idiom, 'voucher', 'address')}:</span> {props.voucher.reservationData.address_hotel}</p>}
                {props.voucher.customerData?.customer_addresses?.map((address, indexc) => {
                  let addressClient = ''
                  props.voucher.reservations?.forEach((element, index) => {
                    if (parseInt(address.region_id) === parseInt(element.travel.region)) {
                      addressClient = <><p><span className="font-bold">{translator(idiom, 'voucher', 'address')} ({address.name}):</span> {address.hotel_address}</p></>
                    } else if (element.travel.region === undefined && address.region_id === null) {
                      addressClient = <><p><span className="font-bold">{translator(idiom, 'voucher', 'address')} (TRASLADO):</span> {address.hotel_address}</p></>
                    }
                  });
                  return (addressClient)
                })}
              </div>
            </div>
            <div className="flex flex-col text-left ml-5">
              <div className="flex my-5 text-xl">
                {translator(idiom, 'voucher', 'payment_about')}
              </div>
              <div className="flex flex-row">
                {props.voucher.payments.forEach((payment) => {
                  if (payment.id > lastPaymentId) {
                    lastPaymentId = payment.id
                  }
                })}
                <div className="flex flex-col mr-4">
                  <p><span className="font-bold">{translator(idiom, 'voucher', 'references')}:</span> {props.voucher.references}</p>
                  <p><span className="font-bold">{translator(idiom, 'voucher', 'purchase_date')}:</span> {dateLocale(props.voucher.created_at)}</p>
                  {props.voucher.payments.map((payment) => {
                    if (payment.id === lastPaymentId) {
                      return <p><span className="font-bold">{translator(idiom, 'voucher', 'last_purchase_date')}:</span> {dateLocale(payment.created_at)}</p>
                    }
                  })}
                </div>
                {props.voucher.payments.map((payment) => {
                  if (payment.id === lastPaymentId) {
                    return <div className="flex flex-col ml-4">
                      <p><span className="font-bold">{translator(idiom, 'voucher', 'total_value')}:</span> <NumberFormat
                        value={Number(props.voucher.total) || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span>{value}</span>}
                      /></p>
                      <p><span className="font-bold">{translator(idiom, 'voucher', 'paid_value')}:</span> <NumberFormat
                        value={Number(props.voucher?.payments.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total), 0)) || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span>{value}</span>}
                      /></p>
                      <p><span className="font-bold">{translator(idiom, 'voucher', 'pending_value')}:</span> <NumberFormat
                        value={props.voucher.payment_status !== "accomplished" ? Number((props.voucher.total) - props.voucher?.payments.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total), 0)) : 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span>{value}</span>}
                      /></p>
                      <p><span className="font-bold">{translator(idiom, 'voucher', 'payment_status')}:</span> {payment_status[payment.current_status]}</p>
                    </div>
                  }
                })}
              </div>
            </div>
          </div>

          {props.voucher.status === 'canceled' && <>Reserva cancelada por {props.voucher.logs?.name} em <Moment format="DD/MM/YYYY HH:MM" date={props.voucher.logs?.created_at} /></>}

          <div className="flex justify-center mb-5 mt-12 text-xl ">
            {translator(idiom, 'voucher', 'about_reservation')}
          </div>

          {props.voucher.reservations.map((v) => (
            <div className="flex flex-row">
              <div className="flex flex-col">
                <p className="my-3 text-lg text-left">
                  {v?.type === 'tour' ? v?.travel.name : v?.travel.region_name}
                  {v?.type === 'transfer' ? `(Transfer ${v?.transfer_type})` : ''}
                </p>
                <div className="flex flex-row justify-between">
                  <div className="grid grid-cols-8 lg:grid-cols-4 justify-between">
                    <div className="col-span-3 lg:col-span-2">
                      <div className="flex text-left">
                        <div className="flex flex-row flex-wrap mx-2">
                          <span className="mx-2 flex"><span className="mr-2"><img src={calendar} /></span> {formatDate(v?.tour_date)} {/*<Moment format="DD/MM/YYYY" date={v?.tour_date} />*/}</span>
                          <span className="mx-2 flex"><span className="mr-2"><img src={clock} /></span> {v?.times !== null ? getTimes(v) : formatHour(v?.flight_time) || '-'}</span>
                          <span className="mx-2 flex"><span className="mr-2"><img src={person} /></span> {v?.adults_total} adultos, {v?.kids_total} crianças</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-5 lg:col-span-2">
                      <div className="flex text-left">
                        <div className="flex flex-row">
                          {v?.type === 'tour' && <div className="flex flex-col mx-3"><span><span className="font-bold">{translator(idiom, 'voucher', 'value_by_adult')}:</span> <NumberFormat
                            value={calcAdultsValue(v)}
                            displayType={'text'}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalPrecision={2}
                            thousandSeparator={"."}
                            isNumericString
                            prefix={`${process.env.REACT_APP_CURRENCY} `}
                            renderText={(value) => value}
                          /></span>
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'value_by_kid')}:</span> <NumberFormat
                              value={calcKidsValue(v)}
                              displayType={'text'}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalPrecision={2}
                              thousandSeparator={"."}
                              isNumericString
                              prefix={`${process.env.REACT_APP_CURRENCY} `}
                              renderText={(value) => value}
                            /></span>
                          </div>
                          }

                          {v?.type === 'transfer' && <div className="flex flex-col mx-3"><span><span className="font-bold">{translator(idiom, 'voucher', 'value_by_passagers')}:</span> <NumberFormat
                            value={Number(v?.adults_total_amount) || 0}
                            displayType={'text'}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalPrecision={2}
                            thousandSeparator={"."}
                            isNumericString
                            prefix={`${process.env.REACT_APP_CURRENCY} `}
                            renderText={(value) => value}
                          />
                          </span>
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'passagers')}:</span> {v?.adults_total}</span>
                          </div>}

                          <div className="flex flex-col mx-3">
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'subtotal')}:</span> <NumberFormat
                              value={Number(v?.price) + Number(v?.customer_discount) || 0}
                              displayType={'text'}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalPrecision={2}
                              thousandSeparator={"."}
                              isNumericString
                              prefix={`${process.env.REACT_APP_CURRENCY} `}
                              renderText={(value) => value}
                            /></span>
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'discount')}:</span> <NumberFormat
                              value={Number(v?.customer_discount) || 0}
                              displayType={'text'}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalPrecision={2}
                              thousandSeparator={"."}
                              isNumericString
                              prefix={`${process.env.REACT_APP_CURRENCY} `}
                              renderText={(value) => value}
                            />
                              <span>{`(${((Number(v?.customer_discount) / (Number(v?.price) + Number(v?.customer_discount))) * 100).toFixed(2)}%)`}</span>
                            </span>
                          </div>
                          <div className="flex flex-col ml-3">
                            <span><span className="font-bold">{translator(idiom, 'voucher', 'total')}:</span> <NumberFormat
                              value={Number(v?.price) || 0}
                              displayType={'text'}
                              decimalSeparator={","}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalPrecision={2}
                              thousandSeparator={"."}
                              isNumericString
                              prefix={`${process.env.REACT_APP_CURRENCY} `}
                              renderText={(value) => value}
                            /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="flex mt-16">
            <div className="flex flex-col flex-1 space-y-1 text-start" style={{ 'text-align': 'justify' }}>
              <div dangerouslySetInnerHTML={{ __html: cleanText(props.voucher?.terms?.description) }} /><br />
              <div dangerouslySetInnerHTML={{ __html: cleanText(props.voucher?.terms?.description2) }} style={{ 'font-weight': 'bold' }} /><br />
              <div dangerouslySetInnerHTML={{ __html: cleanText(props.voucher?.terms?.description3) }} />
            </div>
          </div>
          <div className="flex-row mt-16">
            {props.voucher?.signed_by_customer &&
              <>
                <p>{translator(idiom, 'voucher', 'signature')} {dateLocale(props.voucher?.signature_date)}</p>
                <p>{props.voucher?.customerData?.email} - {translator(idiom, 'voucher', 'document')} {props.voucher?.customerData?.document} - IP {props.voucher?.signature_ip}</p>
                <p>{props.voucher?.signatures?.public_key}</p>
              </>}
          </div>
          <div className="flex mt-4">
            <div className="flex flex-col flex-1 space-y-1 text-center">
              <p>{translator(idiom, 'voucher', 'customer_name')}: {props.voucher.customerData.name}</p>
              <p>ID: {props.voucher.id}</p>
              <p>{translator(idiom, 'voucher', 'purchase_date')}: {dateLocale(props.voucher.created_at)}</p>
            </div>
          </div>
        </>
      </div>

      <div className="p-8 bg-white border-1 text-blue block xs:block sm:block md:block lg:hidden xl:hidden" style={{ borderRadius: '10px 10px 0px 0px', fontSize: '14px', display: 'none' }}>
        <>
          <div className="flex justify-space-around">
            <div className="flex flex-col text-center mr-3">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center" alt="" style={{ width: '80px' }} />
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <div className="text-left" style={{ fontSize: '0.7rem' }}>
                <div className="flex flex-col text-lg">
                  <p>Voucher de reserva</p>
                </div>
                <p className="text-left">{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address}</p>
                <p className="text-left">{props.voucher.company.district}</p>
                <p className="text-left">{props.voucher.company.state} - {props.voucher.company.country}</p>
                <p className="text-left">{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
                <p className="text-left">{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="my-4 border-t border-b border-solid border-lightgray"></div>
          {props.voucher.status === 'canceled' && <>Reserva cancelada por {props.voucher.logs.name} em <Moment format="DD/MM/YYYY HH:MM" date={props.voucher.logs.created_at} /></>}
          <div className="flex my-5 text-lg ">
            {translator(idiom, 'voucher', 'customers_data')}
          </div>
          <div className="flex flex-col space-y-1 text-left">
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'customer_name')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.reservationData.customer_name}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'phone')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.reservationData.customer_telephone}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'email')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.customerData.email}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'document')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.customerData.document}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  Idioma:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {language[props.voucher.customerData.language]}
                </div>
              </div>
            </div>
            {props.voucher?.reservationData?.combo_id && <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'address')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                  {props.voucher.reservationData.address_hotel}
                </div>
              </div>
            </div>}
            {props.voucher?.reservationData?.translate_id && <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'address')}:
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.reservationData.address_hotel}
                </div>
              </div>
            </div>}
            {props.voucher.customerData?.customer_addresses?.map((address, indexc) => {
              let addressClient = ''
              props.voucher.reservations?.forEach((element, index) => {
                if (parseInt(address.region_id) === parseInt(element.travel.region)) {
                  addressClient = <><div className="flex flex-col" style={{ width: '100%' }}>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                        {translator(idiom, 'voucher', 'address')} ({address.name}):
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {address.hotel_address}
                      </div>
                    </div>
                  </div>
                  </>
                } else if (element.travel.region === undefined && address.region_id === null) {
                  addressClient = <><div className="flex flex-col" style={{ width: '100%' }}>
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                        {translator(idiom, 'voucher', 'address')} (TRASLADO):
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {address.hotel_address}
                      </div>
                    </div>
                  </div>
                  </>
                }
              });
              return (addressClient)
            })}
          </div>

          <div className="flex mt-12 mb-5 text-lg">
            {translator(idiom, 'voucher', 'payment_about')}
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="px-4 my-1"></div>
              <div className="flex flex-col items-start flex-1 space-y-1">
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'references')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                    {props.voucher.references}
                  </div>
                </div>
              </div>
              <div className="px-4 my-1"></div>
              <div className="flex flex-col items-start flex-1 space-y-1">
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'purchase_date')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                    {dateLocale(props.voucher.created_at)}
                  </div>
                </div>
              </div>
            </div>
            {props.voucher.payments.forEach((payment) => {
              if (payment.id > lastPaymentId) {
                lastPaymentId = payment.id
              }
            })}
            {props.voucher.payments.map((payment) => {
              if (payment.id === lastPaymentId) {
                return <div>
                  <div className="px-4 my-1"></div>
                  <div className="flex flex-col items-start flex-1 space-y-1">
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'last_purchase_date')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {dateLocale(payment.created_at)}
                      </div>
                    </div>
                  </div>

                  <div className="px-4 my-1"></div>
                  <div className="flex flex-col items-start flex-1 space-y-1">
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'total_value')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        <NumberFormat
                          value={Number(props.voucher.total) || 0}
                          displayType={'text'}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalPrecision={2}
                          thousandSeparator={"."}
                          isNumericString
                          prefix={`${process.env.REACT_APP_CURRENCY} `}
                          renderText={(value) => <span className="flex items-center">{value}</span>}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 my-1"></div>
                  <div className="flex flex-col items-start flex-1 space-y-1">
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'paid_value')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        <NumberFormat
                          value={Number((props.voucher?.payments.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total), 0))) || 0}
                          displayType={'text'}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalPrecision={2}
                          thousandSeparator={"."}
                          isNumericString
                          prefix={`${process.env.REACT_APP_CURRENCY} `}
                          renderText={(value) => <span className="flex items-center">{value}</span>}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 my-1"></div>
                  <div className="flex flex-col items-start flex-1 space-y-1">
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'pending_value')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        <NumberFormat
                          value={Number(payment.pending_total) || 0}
                          displayType={'text'}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalPrecision={2}
                          thousandSeparator={"."}
                          isNumericString
                          prefix={`${process.env.REACT_APP_CURRENCY} `}
                          renderText={(value) => <span className="flex items-center">{value}</span>}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 my-1"></div>
                  <div className="flex flex-col items-start flex-1 space-y-1">
                    <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                      <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                        {translator(idiom, 'voucher', 'payment_status')}
                      </div>
                      <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                        {payment_status[payment.current_status]}
                      </div>
                    </div>
                  </div>
                </div>
              }
            })}
          </div>

          <div className="flex mb-5 mt-12 text-lg">
            {translator(idiom, 'voucher', 'about_reservation')}
          </div>
          <div className="flex flex-col">
            {props.voucher.reservations.map((v) => (
              <div className="flex flex-col" style={{ width: '100%' }}>
                <>
                  <p className="my-3 text-left font-bold">
                    {v?.type === 'tour' ? v?.travel.name : v?.travel.region_name}
                    {v?.type === 'transfer' ? `(Transfer ${v?.transfer_type})` : ''}
                  </p>
                </>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                    {translator(idiom, 'voucher', 'schedule')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                    {v?.times !== null ? getTimes(v) : formatHour(v?.flight_time) || '-'}
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                    {translator(idiom, 'voucher', 'date')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                    {formatDate(v?.tour_date)}
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold text-left">
                    Reservado em
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                    {formatDate(v?.created_at)}
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                {v?.type === 'tour' && <>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex flex-col items-start justify-center flex-1 space-y-1 font-bold">
                      {translator(idiom, 'voucher', 'value_by_adult')}
                    </div>
                    <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                      <NumberFormat
                        value={calcAdultsValue(v)}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </div>
                  </div>
                  <div className="px-4 my-1"></div>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                      {translator(idiom, 'voucher', 'adults')}
                    </div>
                    <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                      {v?.adults_total}
                    </div>
                  </div>
                  <div className="px-4 my-1"></div>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex flex-col items-start justify-center flex-1 space-y-1 font-bold">
                      {translator(idiom, 'voucher', 'value_by_kid')}
                    </div>
                    <span class="flex space-x-1">
                      <span>
                        <NumberFormat
                          value={calcKidsValue(v)}
                          displayType={'text'}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalPrecision={2}
                          thousandSeparator={"."}
                          isNumericString
                          prefix={`${process.env.REACT_APP_CURRENCY} `}
                          renderText={(value) => <span className="flex items-center">{value}</span>}
                        />
                      </span>
                    </span>
                  </div>
                  <div className="px-4 my-1"></div>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                      {translator(idiom, 'voucher', 'kids')}
                    </div>
                    <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                      {v?.kids_total}
                    </div>
                  </div>
                </>}
                {v?.type === 'transfer' && <>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex flex-col items-start justify-center flex-1 space-y-1 font-bold">
                      {translator(idiom, 'voucher', 'value_by_passagers')}
                    </div>
                    <span>
                      <NumberFormat
                        value={Number(v?.adults_total_amount) || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </span>
                  </div>
                  <div className="px-4 my-1"></div>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                      {translator(idiom, 'voucher', 'passagers')}
                    </div>
                    <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                      {v?.adults_total}
                    </div>
                  </div>
                </>}
                <div className="px-4 my-1"></div>

                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'subtotal')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                    <NumberFormat
                      value={Number(v?.price) + Number(v?.customer_discount) || 0}
                      displayType={'text'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      decimalPrecision={2}
                      thousandSeparator={"."}
                      isNumericString
                      prefix={`${process.env.REACT_APP_CURRENCY} `}
                      renderText={(value) => <span className="flex items-center">{value}</span>}
                    />
                  </div>
                </div>
                <div className="px-4 my-1"></div>
                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'discount')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                    <span class="flex space-x-1">
                      <NumberFormat
                        value={Number(v?.customer_discount) || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                      <span>{`(${((Number(v?.customer_discount) / (Number(v?.price) + Number(v?.customer_discount))) * 100).toFixed(2)}%)`}</span>
                    </span>
                  </div>
                </div>
                <div className="px-4 my-1"></div>

                <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                  <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                    {translator(idiom, 'voucher', 'total')}
                  </div>
                  <div className="flex flex-col items-end justify-center flex-1 space-y-1">
                    <span>
                      <NumberFormat
                        value={Number(v?.price) || 0}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </span>
                  </div>
                </div>

                <div className="my-4"></div>
              </div>
            ))}
          </div>
          <div className="flex flex-col text-left">
            <div dangerouslySetInnerHTML={{ __html: cleanText(props.voucher?.terms?.description) }} /><br />
            <div dangerouslySetInnerHTML={{ __html: cleanText(props.voucher?.terms?.description2) }} style={{ 'font-weight': 'bold' }} /><br />
            <div dangerouslySetInnerHTML={{ __html: cleanText(props.voucher?.terms?.description3) }} />
          </div>
          <div className="flex-row mt-16">
            {props.voucher?.signed_by_customer &&
              <>
                <p>{translator(idiom, 'voucher', 'signature')} {dateLocale(props.voucher?.signature_date)}</p>
                <p>{props.voucher?.customerData?.email} - {translator(idiom, 'voucher', 'document')} {props.voucher?.customerData?.document} - IP {props.voucher?.signature_ip}</p>
                <p>{props.voucher?.signatures?.public_key}</p>
              </>}
          </div>
          <div className="flex flex-col mt-4">
            <p>{translator(idiom, 'voucher', 'customer_name')}: {props.voucher.customerData.name}</p>
            <p>ID: {props.voucher.id}</p>
            <p>{translator(idiom, 'voucher', 'purchase_date')}: {dateLocale(props.voucher.created_at)}</p>
          </div>
        </>
      </div>
    </>
  }
}

const VoucherLinkExit = props => {
  let { path } = useRouteMatch();
  const history = useHistory();
  const componentRef = useRef()
  const { sale_id, token } = useParams();
  const [voucher, setVoucher] = useState({});
  const [loading, setLoading] = useState(true);
  const [sendingVoucher, setSendingVoucher] = useState(false);
  let url = token ? `/customer/get_sale_voucher/${sale_id}?access_token=${token}` : `/customer/get_sale_voucher/${sale_id}?sale_id=${sale_id}`;
  const [success, setSuccess] = useState(false);
  const sendVoucherUrl = `sales/${sale_id}/send_voucher`;
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [checked, setChecked] = useState(true);
  const [loadingSignature, setLoadingSignature] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [successCode, setSuccessCode] = useState(false);
  const [ip, setIP] = useState('');
  const [code, setCode] = useState('');
  const [codeConfirm, setCodeConfirm] = useState('');
  const [error, setError] = React.useState(false);

  const getVoucher = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setVoucher(response.data);
        setLoading(false);
      }
    }).catch(function (response) {
      history.push('/');
    });
  }

  const sendVoucher = () => {
    setSendingVoucher(true);
    apiClient.httpInstance.post(sendVoucherUrl).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setSendingVoucher(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    });
  }

  const changeCodeConfirm = (value) => {
    setCodeConfirm(value)
  }

  const sendSignCode = (customer_id, customer_email) => {
    setLoadingCode(true)
    apiClient.httpInstance.post(`sales/${sale_id}/send_code_signature/${customer_id}`, { "email": customer_email }).then(response => {
      if (response.status === 200) {
        getVoucher(url);
        setLoadingCode(false)
        setSuccessCode(true)
      }
    });
  }

  const confirmSignature = () => {
    setError(false)
    if (!codeConfirm) {
      setLoadingSignature(false)
      setError(true)
      return
    }
    setLoadingSignature(true)
    apiClient.httpInstance.post(`sales/${sale_id}/sale_signature`, {
      customer_ip: ip,
      customer_hash: codeConfirm
    }).then(response => {
      if (response.status === 200) {
        getVoucher(url);
        setOpenModal(false)
        setSuccessModal(true)
      }
    }).catch(function (response) {
      setLoadingSignature(false)
      setError(true)
    });
    ;
  }

  //creating function to load ip address from the API
  const getIpClient = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
    /* apiClient.httpInstance.get('myIpSignature').then(response => {
      if (response.status === 200) {
          setIP(response.data.ip)
        }
    }); */
  }

  useEffect(() => {
    getVoucher(url);
    getIpClient();
  }, [url]);

  let theme = AdminConfig.themes[slugfy(props?.user?.data?.permission)] || AdminConfig.themes.logistica;

  return <Switch>
    <React.Fragment>
      <div className="flex flex-col admin">
        <Header theme={theme} />
        <div className="flex flex-row bg-lightgray">
          <Route path={`${path}`}>
            <div className="w-full flex justify-center mx-0 mb-20 pt-14 md:mx-28">
              <>
                {!loading && <div className="px-7">
                  <div className="flex items-center justify-between mt-2 text-2xl text-blue flex-wrap">
                    <div className="w-96 md:w-0">Voucher</div>
                    <div className="space-x-3 w-100">
                      {voucher?.signed_by_customer ?
                        <Button
                          textcolor="#FFF"
                          padding="6px 15px"
                          width="200"
                        >
                          <Link color="inherit" to={`/sign/${sale_id}`} target='_blank'>
                            Exibir Assinatura
                          </Link>
                        </Button>
                        :
                        <Button
                          textcolor="#FFF"
                          padding="6px 15px"
                          width="200"
                          //disabled={voucher?.signed_by_customer ? true : false}
                          onClick={() => { setOpenModal(true); sendSignCode(voucher?.customerData?.id, voucher?.customerData?.email); } }
                        >
                          Assinar
                        </Button>
                      }
                      <ReactToPrint
                        trigger={() => <Button
                          textcolor="#FFF"
                          padding="6px 15px"
                          width="200"
                        >
                          Visualizar PDF
                        </Button>
                        }
                        content={() => componentRef.current}
                        onBeforeGetContent={() => {
                          document.getElementById('desktop').classList.remove('hidden')
                          document.getElementById('desktop').classList.remove('sm:hidden')
                          document.getElementById('desktop').classList.remove('md:hidden')
                        }}
                        onAfterPrint={() => {
                          document.getElementById('desktop').classList.add('hidden')
                          document.getElementById('desktop').classList.add('sm:hidden')
                          document.getElementById('desktop').classList.add('md:hidden')
                        }}
                        onPrintError={() => {
                          document.getElementById('desktop').classList.add('hidden')
                          document.getElementById('desktop').classList.add('sm:hidden')
                          document.getElementById('desktop').classList.add('md:hidden')
                        }}
                        pageStyle="@page { size: 10in 13.5in; }"
                      />
                    </div>
                  </div>
                  <div className="my-4 d-flex">
                    {success && <Alert className="mt-5" severity="success" color="success">
                      Voucher enviado com sucesso
                    </Alert>}
                  </div>
                  <Content
                    {...props}
                    voucher={voucher}
                    ref={componentRef}
                  />
                </div>}
              </>
            </div>
          </Route>
        </div>
      </div>

      <Modal open={openModal} style={{ width: '90%', maxWidth: '450px' }}>
        <div className="justify-end text-center md:flex w-100">
          <Icon className="cursor-pointer" onClick={(ev) => setOpenModal(false)}>close</Icon>
        </div>
        {voucher?.signed_by_customer ?
          <><div className="flex mt-4">
            <p>Assinatura realizada em {dateLocale(voucher?.signature_date)}</p>
          </div>
            <div className="flex mt-4">
              <p style={{ wordBreak: 'break-word' }}>Email: {voucher?.customerData?.email}</p>
            </div>
            <div className="flex mt-4">
              <p>CPF: {voucher?.customerData?.document}</p>
            </div>
            <div className="flex my-4">
              <p>IP: {voucher?.signature_ip}</p>
            </div>
          </>
          :
          <form>
            {error && (<Alert className="mb-5" variant="filled" severity="error">
              Chave(s) inválida(s)!
            </Alert>)}
            <div className="flex mt-4 text-gray">
              <p>Dados do assinante:</p>
            </div>
            <div className="flex mt-4">
              <p>Email: {voucher?.customerData?.email}</p>
            </div>
            <div className="flex mt-4">
              <p>CPF: {voucher?.customerData?.document}</p>
            </div>
            <div className="flex mt-4">
              <p>IP: {ip}</p>
            </div>
            <div className="flex mt-4 ml-2">
              <Checkbox
                className="p-0"
                value={checked}
                checked={checked}
                onChange={(ev) => setChecked(ev.target.checked, true)}
                color="dark"
              /><p onClick={() => setChecked(!checked)}>Declaro que estou de acordo</p>
            </div>
            {!ip && <div className="justify-center text-center mt-4 md:flex w -100">
              <Button
                textcolor="#fff"
                padding="10px 15px"
                margintop="0px"
                width="300px"
                disabled={!checked}
                onClick={(ev) => getIpClient()}>
                Atualizar IP
              </Button>
            </div>}
            <div className="text-gray mt-4 md:flex w-100">
              <p>Para confirmar sua assinatura, insira abaixo a chave que foi enviada para seu e-mail:</p>
            </div>
            <div className="justify-center text-center mt-4 md:flex w -100">
              <TextField
                className="inputRounded"
                label="Insira a sua chave de assinatura"
                variant="outlined"
                type="text"
                value={codeConfirm}
                onChange={(ev) => changeCodeConfirm(ev.target.value)}
                required
              />
            </div>
            {loadingCode ?
              <div className="justify-center text-center mt-4 md:flex w-100">
                <Button
                  textcolor="#212121"
                  variant="link"
                  bgColor="transparent"
                  margintop="0px"
                  width="100%"
                  disabled={!checked}
                  onClick={(ev) => {
                    sendSignCode(voucher?.customerData?.id, voucher?.customerData?.email)
                  }}
                >
                  <div className="flex-1 text-center">
                    <CircularProgress size={20} />
                  </div>
                </Button>
              </div>
              :
              <div className="text-gray mt-4 w-100">
                Não sabe qual sua chave?{' '}
                <Link to={`#`}
                  onClick={(ev) => {
                    sendSignCode(voucher?.customerData?.id, voucher?.customerData?.email)
                  }}
                  className="" style={{ textDecoration: 'underline' }}>
                  Clique aqui
                </Link>{' '}
                para receber novamente por email
              </div>
            }
            <div className="justify-center text-center my-4 md:flex w-100">
              <Button
                textcolor="#fff"
                padding="10px 15px"
                margintop="0px"
                width="300px"
                disabled={!checked}
                onClick={(ev) => {
                  confirmSignature()
                }}
              >
                {loadingSignature ?
                  <div className="flex-1 text-center">
                    <CircularProgress size={20} />
                  </div>
                  :
                  'Confirmar assinatura'
                }
              </Button>
            </div>
          </form>
        }
      </Modal>

      <Modal open={successModal} style={{ width: '100%', maxWidth: '350px' }}>
        <div className="justify-end text-center md:flex w-100">
          <Icon className="cursor-pointer" onClick={(ev) => setSuccessModal(false)}>close</Icon>
        </div>
        <div className="flex mt-4">
          <p>Assinatura realizada em {dateLocale(voucher?.signature_date)}</p>
        </div>
        <div className="flex mt-4">
          <p>Email: {voucher?.customerData?.email}</p>
        </div>
        <div className="flex mt-4">
          <p>CPF: {voucher?.customerData?.document}</p>
        </div>
        <div className="flex my-4">
          <p>IP: {voucher?.signature_ip}</p>
        </div>
      </Modal>
    </React.Fragment>
  </Switch>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(VoucherLinkExit);
