const monthOptions = [
    {
        value: '01',
        title: 'Janeiro'
    },
    {
        value: '02',
        title: 'Fevereiro'
    },
    {
        value: '03',
        title: 'Março'
    },
    {
        value: '04',
        title: 'Abril'
    },
    {
        value: '05',
        title: 'Maio'
    },
    {
        value: '06',
        title: 'Junho'
    },
    {
        value: '07',
        title: 'Julho'
    },
    {
        value: '08',
        title: 'Agosto'
    },
    {
        value: '09',
        title: 'Setembro'
    },
    {
        value: '10',
        title: 'Outubro'
    },
    {
        value: '11',
        title: 'Novembro'
    },
    {
        value: '12',
        title: 'Dezembro'
    },
];

export { monthOptions };