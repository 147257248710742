import BreadCrumbs from '@components/Breadcrumbs';
import Button from '@components/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { capitalizeFirstLetter, dateLocale, translator } from '@utils/helpers';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

class Content extends React.Component {
  render() {
    const props = this.props;
    const idiom = (props.voucher.customerData.language || 'pt').toUpperCase();
    const payment_status = {
      accomplished: 'Pago',
      pending: 'Pendente',
      partial: 'Parcial'
    };

    const formatHour = time => {
      if (time) {
        const hourArrar = time.split(":")
        const hour = new Date().setHours(hourArrar[0], hourArrar[1]);
        return format(hour, "hh:mm a")
      }
      return null
    }

    const calcAdultsValue = (v) => {
      const adultValue = Number(v?.adults_total_amount)
      const adultQuantity = Number(v?.adults_total)
      const value = adultQuantity > 1 ? adultValue / adultQuantity : adultValue
      return value
    }

    const calcKidsValue = (v) => {
      const adultValue = Number(v?.kids_total_amount)
      const adultQuantity = Number(v?.kids_total)
      const value = adultQuantity > 1 ? adultValue / adultQuantity : adultValue
      return value
    }

    const getTimes = (v) => {
      if (v.travel.tour_period === 'fullday') {
        return 'Integral'
      }
      return v.times
    }

    return <>
      <div className="p-10 bg-white border-1 text-blue" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <>
          <div className="flex justify-between">
            <div className="flex flex-col text-center">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center rounded-full" alt="" style={{ width: '70px', height: '70px', objectFit: 'cover' }} />
              </div>
              <span className="mt-2">{process.env.REACT_APP_NAME}</span>
            </div>
            <div className="flex flex-col space-y-1">
              <div className="text-right">
                <p>{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address}, {props.voucher.company.number} - {props.voucher.company.complement}</p>
                <p>{props.voucher.company.district !== 'null' && props.voucher.company.district !== '' ? props.voucher.company.district : ''}</p>
                <p>{props.voucher.company.state} - {props.voucher.company.country}</p>
                <p>{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
                <p>{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-10 space-y-3 text-xl text-center">
            <p>Documento {process.env.REACT_APP_NAME}</p>
          </div>
          <div className="flex">
            <div className="flex flex-col flex-1 space-y-1 text-center">
              <p>{translator(idiom, 'voucher', 'dates')}</p>
            </div>
            <div className="flex flex-col flex-1 space-y-1 text-center">
              <p>{translator(idiom, 'voucher', 'products')}</p>
            </div>
            <div className="flex flex-col flex-1 space-y-1 text-center">
              <p>{translator(idiom, 'voucher', 'passagers')}</p>
            </div>
          </div>
          {props.voucher.budgets.map((v) => (
            <div className="flex">
              <div className="flex flex-col flex-1 space-y-1 text-center">
                <p><Moment format="DD/MM/YYYY" date={v?.tour_date} /></p>
              </div>
              <div className="flex flex-col flex-1 space-y-1 text-center">
                <p>{v?.travel?.map((t) => {
                  if (t.region_name) {
                    return t.region_name
                  } else {
                    return t.name
                  }
                })} {v?.transfer_type ? `- Transfer ${v?.transfer_type}` : ''}</p>
              </div>
              <div className="flex flex-col flex-1 space-y-1 text-center">
                <p>{v?.travel_type === 'tour' ? parseInt(v?.adults_total) + parseInt(v?.kids_total) : v?.cupos}</p>
              </div>
            </div>
          ))}
          <div className="flex justify-center my-10 text-xl ">
            {translator(idiom, 'voucher', 'customers_data')}
          </div>
          <div className="flex">
            <div className="flex flex-col flex-1 space-y-1">
              <p>{translator(idiom, 'voucher', 'customer_name')}: {props.voucher.customer.name}</p>
              <p>{translator(idiom, 'voucher', 'phone')}: {props.voucher.customer.phone}</p>
              <p>{translator(idiom, 'voucher', 'email')}: {props.voucher.customer.email}</p>
              <p>{translator(idiom, 'voucher', 'document')}: {props.voucher.customer.document}</p>
              {props.voucher?.reservationData?.combo_id && <p>{translator(idiom, 'voucher', 'address')}: {props.voucher.reservationData.address_hotel}</p>}
            </div>
          </div>
          <div className="flex justify-center my-5 text-xl ">
            Informações do vendedor
          </div>
          <div className="flex flex-col space-y-1 text-left">
            <p>Nome do vendedor: {props.voucher?.seller[0]?.name}</p>
            <p>E-mail: {props.voucher?.seller[0]?.email}</p>
          </div>
          <div className="flex justify-center mb-5 mt-12 text-xl ">
            Sobre o orçamento
          </div>
          <div className="flex flex-col">
            {props.voucher.budgets.map((v) => (
              <>
                <div className="flex flex-col" style={{ width: '100%' }}>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex flex-col items-start space-y-1">
                      {console.log(v)}
                      {v?.travel?.map((t) => {
                        if (t.region_name) {
                          return t.region_name
                        } else {
                          return t.name
                        }
                      })}
                    </div>
                  </div>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex items-start flex-1 space-y-1">
                      Validade: <Moment format="DD/MM/YYYY" date={format(new Date(`${v?.created_at}`), "yyyy-MM-dd")} />
                    </div>
                    <div className="flex items-center justify-center flex-1 space-y-1 ">
                      Adultos: {parseInt(v?.adults_total)}
                    </div>
                    <div className="flex items-end justify-end flex-1 space-y-1 ">
                      Subtotal: <NumberFormat
                        value={v.travel_type === 'tour' ? parseFloat(v?.adults_total_amount) + parseFloat(v?.kids_total_amount) : parseInt(v?.adults_total) * v?.total_amount}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </div>
                  </div>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex items-start flex-1 space-y-1">
                      {translator(idiom, 'voucher', 'value_by_adult')}: <NumberFormat
                        value={v.travel_type === 'tour' ? v?.travel[0]?.price : v?.price}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center flex-1 space-y-1 ">
                      Crianças: {v?.kids_total ? parseInt(v?.kids_total) : 0}
                    </div>
                    <div className="flex items-end justify-end flex-1 space-y-1 ">
                      Desconto: <NumberFormat
                        value={v?.discount_total_sale ? parseFloat(v?.discount_total_amount) + ((parseFloat(v?.price - parseFloat(v?.discount_total_amount)) * parseFloat(v?.discount_total_sale) / 100)) : parseFloat(v?.discount_total_amount)}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </div>
                  </div>
                  <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                    <div className="flex items-start flex-1 space-y-1">
                      Valor por criança: <NumberFormat
                        value={v?.travel[0]?.kids_price}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </div>
                    <div className="flex items-end justify-end flex-1 space-y-1 ">
                      Total: <NumberFormat
                        //value={v?.travel_type === 'tour' ? v?.total * v?.cupos - (v?.price * v?.discount_total_sale / 100) - (v?.price * v?.discount_total_sale / 100) : v?.price * v?.cupos - (v?.price * v?.discount_total_sale / 100)}
                        value={v?.travel_type === 'tour' ? parseFloat(v?.adults_total_amount) + parseFloat(v?.kids_total_amount) - parseFloat(v?.discount_total_sale ? parseFloat(v?.discount_total_amount) + parseFloat((v?.price - parseFloat(v?.discount_total_amount)) * v?.discount_total_sale / 100) : parseFloat(v?.discount_total_amount)) : v?.price * v?.cupos - (v?.price * v?.discount_total_sale / 100)}
                        displayType={'text'}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        decimalPrecision={2}
                        thousandSeparator={"."}
                        isNumericString
                        prefix={`${process.env.REACT_APP_CURRENCY} `}
                        renderText={(value) => <span className="flex items-center">{value}</span>}
                      />
                    </div>
                  </div>
                  <div className="px-4 my-2"></div>
                </div>
                <div className="px-4 my-2"></div>
              </>
            ))}
          </div>
          {/* <div className="flex mt-16">
            <div dangerouslySetInnerHTML={{ __html: props.voucher?.terms?.description }} />
          </div> */}
          <div className="flex mt-16">
            <div className="flex flex-col flex-1 space-y-1 text-center">
              <p>{translator(idiom, 'voucher', 'customer_name')}: {props.voucher.reservationData.customer_name}</p>
              <p>ID: {props.voucher.id}</p>
              <p>Data do orçamento: {dateLocale(props.voucher.created_at)}</p>
              <p>Esse orçamento pode sofrer alterações</p>
            </div>
          </div>
        </>
      </div>
    </>
  }
}

const Show = (props) => {
  document.title = `Documento do orçamento - ${process.env.REACT_APP_NAME}`
  const componentRef = useRef()
  const { travel_type, travel_id, presale_id } = useParams();
  const [voucher, setVoucher] = useState({});
  const [loading, setLoading] = useState(true);
  const [sendingVoucher, setSendingVoucher] = useState(false);
  let url = `presales/${presale_id}/document`;
  const [success, setSuccess] = useState(false);
  const sendDocumentUrl = `presales/${presale_id}/send_document`;

  const bradecrumbsConfig = [
    {
      title: 'Início',
      active: true,
      route: '/admin/orcamentos',
    },
    {
      title: 'Novo orçamento',
      active: false,
      route: null,
    },
    {
      title: `${capitalizeFirstLetter(travel_type)}`,
      active: true,
      route: `/admin/orcamentos/${travel_type}`
    },
    {
      title: `Comprar`,
      active: true,
      route: `/admin/orcamentos/${travel_type}/${travel_id}/comprar`
    },
    {
      title: `Orçamento`,
      active: true,
      route: `/admin/orcamentos/detalhe/${presale_id}`
    },
    {
      title: 'Documento',
      active: false
    }
  ]

  const getVoucher = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setVoucher(response.data);
        setLoading(false);
      }
    });
  }

  const sendDocument = () => {
    setSendingVoucher(true);
    apiClient.httpInstance.post(sendDocumentUrl).then(response => {
      if (response.status === 200) {
        setSuccess(true);
        setSendingVoucher(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    });
  }

  useEffect(() => {
    getVoucher(url);
  }, [url]);

  return <>
    {!loading && <>
      <BreadCrumbs items={bradecrumbsConfig} />
      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mt-2 text-2xl text-blue">
          <span>Orçamento</span>
          {!voucher?.discount_total_sale && <div className="space-x-3 w-100">
            <Button
              textcolor="#212121"
              padding="6px 15px"
              width="200px"
              variant="outlined"
              disabled={sendingVoucher}
              onClick={sendDocument}
              bgColor="transparent"
            >
              Enviar por e-mail <span class="ml-2"><CircularProgress style={{ display: sendingVoucher ? 'block' : 'none' }} size={15} /></span>
            </Button>
            <ReactToPrint
              trigger={() => <Button
                textcolor="#FFF"
                padding="6px 15px"
                width="200"
                customcolor={props.theme.color}
              >
                Visualizar PDF
              </Button>
              }
              content={() => componentRef.current}
            />
          </div>}
        </div>
        {success && <Alert className="mt-5" severity="success" color="success">
          Documento enviado com sucesso
        </Alert>}
        <Content
          {...props}
          voucher={voucher}
          ref={componentRef}
        />
      </div>
    </>}
  </>
}

export default Show;
